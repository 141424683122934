import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Header from '../components/Header'
import { Link } from 'gatsby'

class Bestattungen extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet title="Impressum | Bestattungshaus Bartko" />

        <Header isChildPage={true} />
        <div id="main">
          <section id="content" className="main">
            <h1>Impressum</h1>
            <p><b>Anbieter gem. Teledienstgesetz (TDG)</b><br />
              Bestattungshaus Bartko<br />
              Lohmannstraße 135<br />
              06366 Köthen
            </p>

            <p>
              Inhaber: Holger Rösler<br />
              Rechtsform: Einzelunternehmen <br />
              Steuernummer: 116/263/01683<br />
            </p>

            <p><b>Inhaltlich verantwortlich:</b><br />
              Cathleen Rösler<br />
              Bestattungshaus Bartko<br />
              Lohmannstraße 135<br />
              06366 Köthen<br />
            </p>

            <p><b>Design / Gestaltung / Programmierung</b><br />
              Nicolas Gehlert <br />
              &#127760; &nbsp; <Link to="https://ngehlert.com/" target="_blank">https://ngehlert.com/</Link>
            </p>

            <p><b>Kontakt</b><br />
              &#9742; &nbsp; (03496) 55 29 39<br />
              &#128231; &nbsp; bestattungshausbartko@t-online.de<br />
              &#127760; &nbsp; www.bestattungshausbartko.de<br />
            </p>

            <p>
              <b>Urheberrecht und Haftung</b><br />
              Alle Rechte vorbehalten. Sämtliche Texte, Grafiken, Bilder und sonstige Inhalte dieser Website unterliegen dem gültigen Urheberrecht. Jede Vervielfältigung oder Weiterverbreitung in Teilen oder als Ganzes in jedem Medium bedarf der ausdrücklichen Zustimmung des oben genannten inhaltlich Verantwortlichen. Das Bestattungshaus Bartko hat auf seinen Web-Seiten Links zu anderen Internetseiten gelegt. Unter Verweis auf das Urteil des Landgerichts Hamburg vom 12.05.1998 erklärt das Bestattungshaus Bartko, dass es keinerlei Einfluss auf Gestaltung und Inhalte der verlinkten Seiten hat. Es distanziert sich deshalb ausdrücklich von allen Inhalten sämtlicher verlinkter Seiten und macht sich deren Inhalte nicht zu eigen. Diese Erklärung gilt für alle auf der Homepage des Bestattungshauses Bartko gelegten fremden Links.
            </p>



            <center>
              <Link to="/" className="button">
                Zurück zur Übersicht
              </Link>
            </center>
          </section>
        </div>
      </Layout>
    )
  }
}

export default Bestattungen
